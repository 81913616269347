


























import { Component, Vue } from 'vue-property-decorator';
import WealthMoreInvestmentAlternativesBannerViewModel
  from '@/vue-app/view-models/components/banners/wealth-more-investment-alternatives-banner-view-model';

@Component({
  name: 'WealthMoreInvestmentAlternativesBanner',
})
export default class WealthMoreInvestmentAlternativesBanner extends Vue {
  banner_view_model = Vue.observable(new WealthMoreInvestmentAlternativesBannerViewModel());

  handleDiscoverHow() {
    this.$emit('clickDiscoverHow');
  }
}
